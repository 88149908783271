import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Data from './data';
import { TokenRepository } from '../TokenRepository';
import PasswordRecoveryForm from './password-recovery-form';
import ResetPassword from './reset-password';
import "./login.css";

const Login = () => {
    const [username, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const tokenRepository = new TokenRepository();
    const [version, setVersion] = useState('');

    const [message, setMessage] = useState<string>(sessionStorage.getItem('loginMessage'));

    const clearMessage = () => {
        setMessage(null);
        sessionStorage.removeItem('loginMessage');
    }

    useEffect(() => {
        Data.getAppVersion().then((result) => {
            setVersion(result.version);
        });        
    }, []);

    async function LoginUser(credentials) {        
        try {
            setLoading(true);
            var token = await Data.loginUser(credentials.username, credentials.password);
            if (token === false || token === null) {
                tokenRepository.saveToken(null);
            }
            else {
                if (token.isMFARequired) {
                    tokenRepository.holdToken(token);
                    let queryString = "?username=".concat(username);//.concat("&returnUrl=/");
                    window.location.href = process.env.REACT_APP_API_URL.concat("/MFA/InitiateMFA").concat(queryString);
                }
                else {
                    tokenRepository.saveToken(token);
                    window.location.reload();
                }
            }
        }
        finally {
            setLoading(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();                
        await LoginUser({
            username,
            password
        });        
    };
    
    const renderLogin = () => {
        return (
            <div className="p-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-6 col-lg-3 mx-auto">
                            <div className="formContainer">
                                <h2 className="p-2 text-center mb-4 h4" id="formHeading">Login</h2>
                                <form action="#">
                                    <div className="form-group mt-3">
                                        <label className="mb-2" >Username / Email ID </label>
                                        <input className="form-control" id="username" name="username" onChange={e => { setUserName(e.target.value); clearMessage(); }} type="email" />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label className="mb-2" >Password</label>
                                        <input className="form-control" id="password" type="password" name="password" onChange={e => { setPassword(e.target.value); clearMessage(); }} placeholder="password" />
                                    </div>
                                    <div className="mt-3">
                                        <input type="checkbox" /> Remember me
                                    </div>
                                    <div className="mt-3">
                                        <a href="/ResetPassword">Forgot Password</a>
                                    </div>
                                    <button disabled={loading} className="btn btn-success btn-lg w-100 mt-4 login" onClick={handleSubmit}>Login</button>
                                </form>
                                {loading &&
                                    <div className="d-flex align-items-center mt-4">
                                        <div className="spinner-border" role="status"></div>
                                        <strong>&nbsp;&nbsp;Logging in...</strong>
                                    </div>
                                }
                                <div className="text-center fs-6 mt-2">v{version}</div>
                            </div>
                        </div>
                    </div>
                    {message &&
                        <div className="row">
                            <div className="alert alert-info" role="alert">
                                {message}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    };

    const renderPasswordRecovery = () => {
        return (
            <PasswordRecoveryForm />
        );
    };

    const renderResetPasswordForm = () => {
        return (
            <ResetPassword />
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/ResetPassword" element={renderPasswordRecovery()} />
                <Route path="/ResetPasswordWithLink" element={renderResetPasswordForm()} />
                <Route path="*" element={renderLogin()} />
            </Routes>
        </BrowserRouter>
    );
}
export default Login;