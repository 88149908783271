import * as dataRequestService from "./svc-data-request";
import * as $ from "jquery";

var dataService = dataRequestService.GetDataRequestService();

export function LogUserActivity(appRefName: string): JQueryPromise<any> {
    const defer = $.Deferred();

    const data = {
        appRefName
    };

    dataService.SendJsonRequestWithData("POST",
        "/api/ActivityLogging/LogUserActivity",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );

    return defer.promise();
}