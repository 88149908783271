import * as dataRequestService from "../shared/svc-data-request";
import * as $ from "jquery";
import { UserDetails } from "../shared/user-details";

const dataService = dataRequestService.GetDataRequestService();

export function enableSqlTracing(enable: boolean): JQueryPromise<any> {
    const defer = $.Deferred();
    var data = {
        enable
    };
    dataService.SendJsonRequestWithData("POST",
        "/api/Security/EnableSqlTracing",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function getSqlDataTrace(): JQueryPromise<any> {
    const defer = $.Deferred();
    dataService.SendJsonRequestWithData("GET",
        "/api/Security/GetSqlTraceData",
        null,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function clearSqlDataTrace(): JQueryPromise<any> {
    const defer = $.Deferred();
    dataService.SendJsonRequestWithData("POST",
        "/api/Security/ClearSqlTraceData",
        null,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function getMenu(): JQueryPromise<any> {
    const defer = $.Deferred();
    dataService.SendJsonRequestWithData("GET",
        "/api/Menu/GetMenu",
        null,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}
export function getUsersToImpersonate(userLastAndFirstName: string): JQueryPromise<any> {
    const defer = $.Deferred();
    var data = {
        userLastAndFirstName: userLastAndFirstName,
        rowCount: 10
    };
    dataService.SendJsonRequestWithData("GET",
        "/api/Security/SearchForUsersToImpersonate",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}
export function impersonate(userIdToImpersonate: number, displayNameToImpersonate: string): JQueryPromise<any> {
    const defer = $.Deferred();
    var data = {
        userIdToImpersonate: userIdToImpersonate,
        displayNameToImpersonate: displayNameToImpersonate
    };
    dataService.SendJsonRequestWithData("POST",
        "/api/Login/ImpersonateUser",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}
export function stopImpersonating(): JQueryPromise<any> {
    const defer = $.Deferred();

    dataService.SendJsonRequest("POST",
        "/api/Login/StopImpersonating",
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
    );

    return defer.promise();
}
export function getUserDetails(): JQueryPromise<UserDetails> {
    const defer = $.Deferred();
    var data = {};
    dataService.SendJsonRequestWithData("GET",
        "/api/Menu/GetUserDetails",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}
