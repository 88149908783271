import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Data from './data';
import ResetPasswordForm from './reset-password-form';

const ResetPassword: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const emailAddress = queryParams.get('emailAddress');
    const dateToken = queryParams.get('dateToken');
    const emailHash = queryParams.get('emailHash');
    const [resetLinkError, setResetLinkError] = useState<string>('');

    useEffect(() => {
        if (emailAddress) {
            Data.validateResetPasswordRequest(emailAddress, dateToken, emailHash).then(result => {
                if (result.success) {
                    setResetLinkError('');
                } else {
                    setResetLinkError(result.error);
                }
            });
        }
    }, [emailAddress]);

    return (
        <div id="reset-password-form">
            <h1>Reset Password Form</h1>

            {resetLinkError !== '' &&
                <h3>{resetLinkError}</h3>
            }
            {resetLinkError === '' &&
                <ResetPasswordForm emailAddress={emailAddress} dateToken={dateToken} emailHash={emailHash} />
            }
        </div>
    );
};

export default ResetPassword;