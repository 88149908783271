import { useEffect, useState } from "react";
import * as Data from '../login/data';
import './home.scss';

function Home() {
    let today: Date = new Date();
    const [version, setVersion] = useState('');

    useEffect(() => {
        Data.getAppVersion().then((result) => {
            setVersion(result.version);
        });
    }, []);

    return (
        <div id="home" className="container-fluid">
            <div className="row" id="above-the-fold-ctas">
                {/*<div className="row cta-block" id="cta-tools">*/}
                {/*    <div className="col-md-12 mb30">*/}
                {/*        <h1 className="text-center">SPH Webinars</h1>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-1">&nbsp;</div>*/}
                {/*    <div className="col-md-7">*/}
                {/*        <ul>*/}
                {/*            <li>*/}
                {/*                <a href="https://vimeo.com/241240868" target="_blank" rel="noreferrer">Full Training</a>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-4">*/}
                {/*        <ul>*/}
                {/*            <li>*/}
                {/*                <a href="https://vimeo.com/241241736" target="_blank" rel="noreferrer">Service Recovery Training - Alert to QI</a>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="row cta-block zeroPaddingLeft">
                    <h1 className="text-center">Tools</h1>
                </div>
                <div className="row cta-block  zeroPaddingLeft">
                    <div className="col-md-1">&nbsp;</div>
                    <div className="col-md-7">
                        <ul>
                            {/*<li>*/}
                            {/*    <div>*/}
                            {/*        Automated Weekly Report <br />*/}
                            {/*        (Should appear in your menu after signing in to Essentials)*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            <li><a href="https://spconnect.surgerypartners.com" target="_blank" rel="noreferrer">SPConnect</a></li>
                            {/*<li><a href="https://surgerypartners.box.com" target="_blank" rel="noreferrer">Box</a></li>*/}
                            <li><a href="https://workforcenow.adp.com/public/index.htm" target="_blank" rel="noreferrer">ADP&reg; Portal</a></li>
                            <li><a href="https://hostedgateway2.pcpw.adp.com/vpn/index.html" target="_blank" rel="noreferrer">ADP&reg; - Hosting / Citrix</a></li>
                            <li><a href="https://online.adp.com/ipay/login.html" target="_blank" rel="noreferrer">ADP&reg; - IPay Statements - User ID = username@symbion (no .com)</a></li>
                            <li><a href="https://ewallet.adp.com/auth/enroll/adpLogin.faces?TYPE=33554433&REALMOID=06-3d76cfcf-0020-0022-0000-6e0700006e07&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-dgnpATEB6cgeg0dhVVWBr%2fTBdCZXSCZfn1hIUpsGvrvTZTytqjjZbc%2bBNRXP5%2fMk&TARGET=-SM-https%3a%2f%2fireports%2eadp%2ecom%2findex%2ejsf" target="_blank" rel="noreferrer">ADP&reg; - IReports - User ID = username@symbion (no .com)</a></li>
                            {/*<li><a href="http://www.concursolutions.com/" target="_blank" rel="noreferrer">Concur Expense Expert</a></li>*/}
                            {/*<li><a href="https://workforcenow.adp.com/public/index.htm" target="_blank" rel="noreferrer">Employee Benefits</a></li>*/}
                            {/*<li><a href="https://sp.envi.net" target="_blank" rel="noreferrer">ENVI</a></li>*/}
                            <li><a href="https://workforceportal.elabor.com/ezLaborManagerNetRedirect/ClientLogin.aspx?lng=en-US&flgn=0" target="_blank" rel="noreferrer">EzLabor - all Facility Employees - Client Name = SymbionArc</a></li>
                            <li><a href="https://workforceportal.elabor.com/ezLaborManagerNetRedirect/ClientLogin.aspx?lng=en-US&flgn=0" target="_blank" rel="noreferrer">EzLabor - all Hourly Corporate Employees - Client Name = SymbionAmbulRes</a></li>
                            <li><a href="https://workforceportal.elabor.com/ezLaborManagerNetRedirect/ClientLogin.aspx?lng=en-US&flgn=0" target="_blank" rel="noreferrer">EzLabor - all Salary Corporate Employees - Client Name = SymbionInc</a></li>
                            {/*<li><a href="https://surgerypartnersmmu.vids.io" target="_blank" rel="noreferrer">Materials Management University | MMU</a></li>*/}
                            <li><a href="https://surgerypartners.csod.com/" target="_blank" rel="noreferrer">Cornerstone OnDemand</a></li>
                            <li>
                                <a href="http://tableau.surgerypartners.com" target="_blank" rel="noreferrer">Tableau</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul>
                            <li><a href="https://surgerypartners.sharepoint.com/sites/ClinicalOperationsAmericanGroupPublicSite/SitePages/PolicyProcedureTOC.aspx" target="_blank" rel="noreferrer">Clinical Policies and Procedures - American Group</a></li>
                            {/*<li><a href="~/Content/Secured/Doc-link%203.2%20upgrade%20(session%202)-20170914%201755-1.mp4" target="_blank" rel="noreferrer">Doc-Link 3.2 upgrade video</a></li>*/}
                            <li><a href="https://surgerypartners.service-now.com/sp" target="_blank" rel="noreferrer">Help Desk</a></li>
                            <li><a href="https://app.vergehealth.com/authentication/signin?returnUrl=https:%2F%2Fvs1.verge-solutions.com%2FVerge.VSuite.Web%2FauthenticationHandler%3Fstate%3DL1ZlcmdlLlZzdWl0ZS5XZWIvTG9naW4vRXh0ZXJuYWxMb2dpbkNhbGxiYWNrP1JldHVyblVybD1odHRwcyUzQSUyRiUyRnZzMS52ZXJnZS1zb2x1dGlvbnMuY29tJTJGVlN0YW5kYXJkJTJG" target="_blank" rel="noreferrer">Vincident Login</a></li>
                            <li>
                               Web Mail
                                <ul>
                                    {/*<p>Web Mail</p>*/}
                                    <li><a href="https://webmail.surgerypartners.com" target="_blank" rel="noreferrer">Symbion Legacy</a></li>
                                    <li><a href="https://webmail.surgerypartners.com" target="_blank" rel="noreferrer">Surgery Partners</a></li>
                                </ul>
                            </li>
                            <li><a href="http://www.concursolutions.com/" target="_blank" rel="noreferrer">Concur Expense Expert</a></li>
                            <li><a href="https://workforcenow.adp.com/public/index.htm" target="_blank" rel="noreferrer">Employee Benefits</a></li>
                            <li><a href="https://sp.envi.net" target="_blank" rel="noreferrer">ENVI</a></li>
                            {/*<li>*/}
                            {/*    <ul>*/}
                            {/*        <p>Travel and Expense</p>*/}
                            {/*        <li><a href="~/Content/Secured/Essentials/Travel_and_Expense/BusinessTravelAndExpenseMemo.pdf">Business Travel and Expense Memo</a></li>*/}
                            {/*        <li><a href="~/Content/Secured/Essentials/Travel_and_Expense/BusinessTravelAndExpensePolicy.pdf">Business Travel and Expense Policy</a></li>*/}
                            {/*        <li><a href="~/Content/Secured/Essentials/Travel_and_Expense/CorporateCreditCardPolicy.pdf">Corporate Credit Card Policy</a></li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <ul>*/}
                            {/*        <p>Groups and Regions</p>*/}
                            {/*        <li>*/}
                            {/*            <a href="~/Content/Secured/Essentials/GroupsAndRegions/ASC Group - Admin, DON and BOM as of 2-6-20.xlsx">ASC Group</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="~/Content/Secured/Essentials/GroupsAndRegions/National Group - CEO and CFO as of 2-4-20.xls">National Group</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a href="~/Content/Secured/Essentials/GroupsAndRegions/SP National Group Roster 9-13-19.doc">SP National Group Roster</a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row" id="about-content">
                <h1 className="textCenter">How Essentials Operates</h1>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <span className="about-content-widget"><i className="fa fa-cogs" aria-hidden="true"></i></span>
                        <div className="about-content-description">
                            <h3>Strategic Plan</h3>
                            This application allows the company to define its complete strategy at a facility level. From your facilities profile to recruitment, the Strategic Plan module provides immediate insight and breadth of knowledge to effectively run your business.
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <span className="about-content-widget"><i className="fa fa-money" aria-hidden="true"></i></span>
                        <div className="about-content-description">
                            <h3>Capital Expense Request</h3>
                            Define your budget through your strategic plan and then use the Capital Expense Request (CER) to manage it. Understand day-to-day what your expense is from actual to budget while allowing your needs to progress smoothly to purchase.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <span className="about-content-widget"><i className="fa fa-truck" aria-hidden="true"></i></span>
                        <div className="about-content-description">
                            <h3>Material Management</h3>
                            Analytics in Essentials will provide you an understanding of what supplies you need to provide the best healthcare possible while also achieving the best cost for those supplies. Interact directly with Centralized Materials Management department to make what you need available to you.
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <span className="about-content-widget"><i className="fa fa-line-chart" aria-hidden="true"></i></span>
                        <div className="about-content-description">
                            <h3>Financial Reporting</h3>
                            Access to the information you need to make sure your business thrives while providing the top notch healthcare the patient deserves. Transparency to how your business is performancing against key performance indicators useful to your performance.
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <span className="about-content-widget"><i className="fa fa-bar-chart" aria-hidden="true"></i></span>
                        <div className="about-content-description">
                            <h3>Your Month At A Glance</h3>
                            From general volume and revenue all the way through to encounter level detail, view a snapshot of your day-to-day performance. The information that will empower you to make the business decisions that matter.
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <span className="about-content-widget"><i className="fa fa-desktop" aria-hidden="true"></i></span>
                        <div className="about-content-description">
                            <h3>Your Essentials</h3>
                            Your business, your time, your success all at your fingertips.
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div>
                    &copy;{today.getFullYear()} Surgery Partners v{version}
                </div>
            </div>
        </div>
    );
}

export default Home;