import * as React from 'react';
import { Modal, Button } from "react-bootstrap";

export class SqlTraceModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            modalName: 'sql-trace-modal',
        }
    }

    closeModal = () => {
        this.props.handleHideModal();
    }

    clearTrace = () => {
        this.props.handleClearTrace();
    }

    render() {
        return (
            <Modal id={this.state.modalName} show={true} onHide={this.closeModal} size="lg">
                <Modal.Header closeButton>
                    <h4 className="modal-title" id="myModalLabel">Tracing</h4>                    
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-12"><Button variant="primary" style={{ float: "right" }} onClick={this.clearTrace}>Clear</Button></div>
                    </div>                    
                    {this.props.sqlTrace && this.props.sqlTrace.map((item, index) => {
                        return (
                            <div className="trace-message" key={index}>
                                <div className="title">{item.name} ({item.executionTime} ms)</div>
                                <div className="entry-time">{item.entryTime}</div>
                                <div className="technical-details"><label>Server</label><span>{item.server}</span></div>
                                <pre className="message-text">{item.sqlQuery}</pre>
                            </div>
                        );
                    })}
                </Modal.Body>
            </Modal >
        );
    }
}