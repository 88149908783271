import { useState, useEffect } from 'react';
import { TokenRepository } from '../TokenRepository';
import { getMenu, getUsersToImpersonate, impersonate, getUserDetails, stopImpersonating, getSqlDataTrace, clearSqlDataTrace, enableSqlTracing } from "./data";
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { UserDetails } from '../shared/user-details';
import { SqlTraceModal } from './sql-trace-modal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Link } from 'react-router-dom';

function Menu() {
    
    const tokenRepository = new TokenRepository();
    const [menu, setMenu] = useState<any>();
    const [impersonateSearch, setImpersonateSearch] = useState<string>("");
    const [searchResults, setSearchResults] = useState<any>();
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [sqlTraceData, setSqlTraceData] = useState<any>();
    const [sqlTraceModalVisible, setSqlTraceModalVisible] = useState<boolean>(false);

    // tracing
    const [tracingEnabled, setTracingEnabled] = useState<boolean>(false);

    const toggleTracingEnabledHandler = () => {
        console.log(tracingEnabled);
        enableSqlTracing(!tracingEnabled).then(() => {
            setTracingEnabled(!tracingEnabled);
        });        
    }

    useEffect(() => {
        const loadUserDetails = async () => {
            const data = await getUserDetails();
            setUserDetails(data);

            // Check if userDetails is available and then load the menu
            if (data) {
                const menuData = await getMenu();
                setMenu([menuData]);
            }
        };

        loadUserDetails();
    }, []);

    useEffect(() => {
        const searchUsersToImpersonate = async () => {
            if (impersonateSearch) {
                const data = await getUsersToImpersonate(impersonateSearch);
                if (data != null && data.value != null) {
                    setSearchResults(data.value);
                }
            } else {
                setSearchResults([]);
            }
        }
        searchUsersToImpersonate();
    }, [impersonateSearch]);

    const handleSignOut = () => {
        tokenRepository.saveToken(null);
        //sessionStorage.removeItem('routesTaken');
        window.location.reload();
    } 

    const handleGetSqlTrace = async () => {
        const data = await getSqlDataTrace();
        if (data != null) {
            console.log(data.value);
            setSqlTraceData(data.value);
            setSqlTraceModalVisible(true);
        }        
    }

    const handleClearTrace = async () => {
        await clearSqlDataTrace();
        setSqlTraceData(null);
    }

    const hideSqlTrace = () => {
        setSqlTraceModalVisible(false);
    }

    const renderNavLinks = (groupMenuName) => {
        return menu != null && menu[0].menuItems != null
            && menu[0].menuItems.filter(x => x.groupMenuName === groupMenuName
                && x.isHidden === false)
            .map((value) => {
                return <Nav.Link eventKey={value.appId} key={value.appId} as={Link} to={'/' + value.refName}>{value.longName}</Nav.Link>; // appears to be the closest
                //return <NavLink as={Link} to={'/' + value.refName}>{value.longName}</NavLink>;
                //return <Link key={value.appId} className="nav-link" to={'/' + value.refName}>{value.longName}</Link>;
                //return <NavDropdown.Item as="div" eventKey={value.appId} key={value.appId}><Link key={value.appId} className="nav-link" to={'/' + value.refName}>{value.longName}</Link></NavDropdown.Item>;
                //return <Dropdown.Item as="div" eventKey={value.appId} key={value.appId}><Link key={value.appId} className="nav-link" to={'/' + value.refName}>{value.longName}</Link></Dropdown.Item>;
                //return <NavDropdown.Item eventKey={value.appId} key={value.appId} href={'/' + value.refName}>{value.longName}</NavDropdown.Item>;
        })
    }

    const renderSearchResults = (searchResults) => {
        return searchResults != null && searchResults.map((searchResult, index) => {
            return <ListGroup.Item>
                <Button onClick={() => setImpersonatedUser(searchResult.userId, searchResult.displayName)} className="link-button" size="sm" as="a">{searchResult.displayName}</Button>
            </ListGroup.Item>;
        });
    }

    const setImpersonatedUser = async (userId: number, displayNameToImpersonate: string) => {
        var token = await impersonate(userId, displayNameToImpersonate);
        const tokenRepository = new TokenRepository();
        if (token === false || token === null) {
            
        }
        else {
            tokenRepository.saveToken(token);
            window.location.href = '/';
        }
    }

    const stopImpersonation = async () => {
        var token = await stopImpersonating();
        const tokenRepository = new TokenRepository();
        if (token === false || token === null) {

        }
        else {
            tokenRepository.saveToken(token);
            window.location.href = '/';
        }
    }

    const capitalizeFirstCharacter = (value: string): string => {
        if (value.length === 0) {
            return value;
        }
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return (
        <div id="main-nav">            

            <div>
                <div className="row align-items-center" style={{ margin: 0 }}>
                    <div className="navbar-logo">
                        <Link to="/"><div id="essentials-logo"></div></Link>
                    </div>
                    <div className="col navbar-env text-center fs-6">
                        {process.env.REACT_APP_ENV !== 'prod' && 
                            <span>Environment: {capitalizeFirstCharacter(process.env.REACT_APP_ENV)}</span>
                        }
                    </div>
                    <div className="col navbar-user text-end">
                        <div className="text-end">
                            {userDetails &&
                                <div>Signed in as <b>{userDetails.portalAdminUserId > 0 ? userDetails.portalAdminDisplayName : userDetails.displayName}</b></div>
                            }
                            {userDetails && userDetails.portalAdminUserId > 0 &&
                                <div><b>Impersonating:</b> <span className="impersonated-user">{userDetails.displayName}</span></div>
                            }
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            {userDetails &&
                                <div>
                                    {userDetails.isPortalAdmin &&
                                        <ButtonGroup id="tracing-buttons" aria-label="Tracing" className="mx-2">
                                            <Button disabled={!tracingEnabled} onClick={handleGetSqlTrace}><i className="fa fa-database" aria-hidden="true"></i></Button>
                                            <ToggleButton
                                                id="enable-tracing"
                                                type="checkbox"
                                                variant="primary"
                                                value="1"
                                                checked={tracingEnabled}
                                                onChange={toggleTracingEnabledHandler}
                                            >
                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </ToggleButton>
                                        </ButtonGroup>
                                    }
                                    <ButtonGroup aria-label="Account">
                                        <Dropdown className="tools-menu">
                                            <Dropdown.Toggle variant="primary" id="account-dropdown">
                                                <i className="fa fa-user" aria-hidden="true"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {userDetails.isPortalAdmin &&
                                                    <div>
                                                        <div className="heading mb-3">Impersonate User</div>
                                                        <input id="impersonate-searchfield" value={impersonateSearch} className="form-control" onChange={e => setImpersonateSearch(e.target.value)} type="text" placeholder="lastname, firstname"></input>
                                                        {userDetails && userDetails.portalAdminUserId > 0 &&
                                                            <Dropdown.Item onClick={stopImpersonation}>Stop Impersonating</Dropdown.Item>
                                                        }
                                                        <ListGroup>
                                                            {renderSearchResults(searchResults)}
                                                        </ListGroup>
                                                    </div>
                                                }
                                                <Dropdown.Item href="/account">Change Password</Dropdown.Item>
                                                <Dropdown.Item onClick={handleSignOut}>Sign Out</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonGroup>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            { menu &&
                <Nav className="main-nav">
                {
                    menu[0].menuItemGroupsToDisplay.map((value) => {
                        return <Dropdown key={value.groupId} as={NavItem}>
                            <Dropdown.Toggle as={NavLink}>
                                <span className="contents">
                                    <i className={"fa " + value.groupIcon}></i>
                                    <span className="group-menu-name-with-icon">
                                        {value.groupMenuName}
                                    </span>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {renderNavLinks(value.groupMenuName)}
                            </Dropdown.Menu>
                        </Dropdown>
                    })}
                </Nav>
            }
            { !menu &&
                <div>Menu loading</div>
            }
            {sqlTraceModalVisible &&
                <SqlTraceModal handleHideModal={hideSqlTrace} sqlTrace={sqlTraceData} handleClearTrace={handleClearTrace} />
            }
        </div>
    );
}

export default Menu;