export class TokenRepository
{
    getToken() {
        const tokenString = localStorage.getItem('token');
        if (tokenString === "undefined") {
            return null;
        }
        const userToken = JSON.parse(tokenString);
        return userToken;
    };
    saveToken(userToken: any) {
        localStorage.setItem('token', JSON.stringify(userToken));
    };
    holdToken(userToken: any) {
        localStorage.setItem('tempToken', JSON.stringify(userToken));
    };
    getHoldToken() {
        const tokenString = localStorage.getItem('tempToken');
        if (tokenString === "undefined") {
            return null;
        }
        const userToken = JSON.parse(tokenString);
        return userToken;
    };
    clearHoldToken() {
        localStorage.removeItem('tempToken');
    }       
}