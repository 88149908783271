import React, { useState, ChangeEvent, FormEvent } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, Link } from 'react-router-dom';
import * as Data from './data';
import * as userMessaging from "../shared/svc-user-messaging";
import RecaptchaScriptLoader from './recaptcha-script-loader';

const PasswordRecoveryForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [isCaptchaVerified, setIsCaptchaVerified] = useState<boolean>(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState<string>('');
    const [submitResponse, setSubmitResponse] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setSubmitResponse('');
    };

    const handleCaptchaChange = (value: string | null) => {
        setRecaptchaResponse(value);
        if (value) {
            setSubmitResponse('');
            setIsCaptchaVerified(true);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitResponse('');
        if (isCaptchaVerified) {
            handleResetPasswordFormSubmit(email, recaptchaResponse);
        } else {
            setSubmitResponse('Please complete the reCAPTCHA verification.');
            setIsSubmitting(false);
        }
    };

    const handleResetPasswordFormSubmit = (email: string, recaptchaResponse: string) => {
        //console.log(`Sending recovery email to ${email} with recaptcha response ${recaptchaResponse}...`);
        Data.sendPasswordResetLink(email, recaptchaResponse).then(result => {
            if (result && result.success) {
                let messageService = userMessaging.GetUserMessagingService();
                var message = new userMessaging.UserMessage(
                    "An email has been sent with a link to reset your password.",
                    userMessaging.MessageTypeOption.Success);
                messageService.SendMessage(message);
                navigate("/"); // send back to main page
            } else if (result.error !== '') {
                let messageService = userMessaging.GetUserMessagingService();
                var message = new userMessaging.UserMessage(
                    result.error,
                    userMessaging.MessageTypeOption.Error);
                messageService.SendMessage(message);
                setIsSubmitting(false);
            }            
        });
    };

    return (
        <div className="p-4">
            <RecaptchaScriptLoader siteKey="6LdSFHwoAAAAAFCQGXiGNRP42m8C9boeTsEvpc9P" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mx-auto">
                        <div className="formContainer">
                            <h2 className="p-2 text-center mb-4 h4" id="formHeading">Password Recovery</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mt-3">
                                    <label htmlFor="emailAddress" className="form-label mb-2">Enter your corporate email address</label>                                    
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={email}
                                        className="form-control"
                                        onChange={(e) => handleEmailChange(e)}
                                        required
                                    />
                                    <div className="invalid-feedback">Please enter a valid email address.</div>
                                </div>
                                <div id="capatcha-container" className="mb-2">
                                    <ReCAPTCHA
                                        sitekey="6LdSFHwoAAAAAFCQGXiGNRP42m8C9boeTsEvpc9P"
                                        onChange={handleCaptchaChange}
                                    />
                                </div>
                                {submitResponse !== '' &&
                                    <div className="alert alert-warning" role="alert">{submitResponse}</div>
                                }
                                <Link to="/">
                                    <button type="button" className="btn btn-secondary me-3">Return to Login</button>
                                </Link>
                                <button type="submit" disabled={email === '' || isSubmitting} className="btn btn-primary">Send Password Reset Email</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordRecoveryForm;
