import * as toaster from "toastr";

export function GetUserMessagingService(): IUserMessagingService {
    var messenger = new UserMessagingService();
    messenger.ConfigureMessenger();
    return messenger;
}

export interface IUserMessagingService {
    SendMessage(message: UserMessage): void;
}

export class UserMessage {
    constructor(messageText: string, messageType: MessageTypeOption) {
        this.MessageText = messageText;
        this.MessageType = messageType;
    }
    MessageText: string;
    MessageType: MessageTypeOption;
}

export enum MessageTypeOption {
    Success,
    Info,
    Error,
    Warning
}

class UserMessagingService implements IUserMessagingService {

    ConfigureMessenger() {
        toaster.options.positionClass = "toast-bottom-center";
    }

    SendMessage(message: UserMessage) {
        switch (message.MessageType) {
            case MessageTypeOption.Success:
                toaster.success(message.MessageText, null, { preventDuplicates: true });
                break;
            case MessageTypeOption.Info:
                toaster.info(message.MessageText, null, { preventDuplicates: true });
                break;
            case MessageTypeOption.Error:
                toaster.error(message.MessageText, null, { preventDuplicates: true });
                break;
            case MessageTypeOption.Warning:
                toaster.warning(message.MessageText, null, { preventDuplicates: true });
                break;
            default:
                break;
        }
    }
}