import { useState, useEffect, FormEvent, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import * as Data from "./data";
import * as userMessaging from "../shared/svc-user-messaging";
import { useNavigate, Link } from "react-router-dom";
import RecaptchaScriptLoader from './recaptcha-script-loader';

interface ResetPasswordFormProps {
    emailAddress: string,
    dateToken: string,
    emailHash: string
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({emailAddress, dateToken, emailHash}) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [newPasswordValidationFailure, setNewPasswordValidationFailure] = useState("");
    const navigate = useNavigate();

    const [isCaptchaVerified, setIsCaptchaVerified] = useState<boolean>(false);
    const [recaptchaResponse, setRecaptchaResponse] = useState<string>('');
    const [submitResponse, setSubmitResponse] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const recaptchaRef = useRef(null);

    const handleChangePassword = () => {
        if (newPassword !== confirmNewPassword) {
            setNewPasswordValidationFailure("The two password values do not match");
            return;
        }

        Data.resetPassword(newPassword, emailAddress, dateToken, emailHash, recaptchaResponse).then(result => {
            if (result) {
                if (result.success === true && result.value.length === 0) {                    
                    let messageService = userMessaging.GetUserMessagingService();
                    var message = new userMessaging.UserMessage(
                        'Password changed successfully',
                        userMessaging.MessageTypeOption.Success);
                    messageService.SendMessage(message);
                    navigate("/"); // send back to main page
                } else {
                    setNewPasswordValidationFailure(result.value[0]);
                    resetCaptcha();
                }
            }
            setIsSubmitting(false);
        });
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitResponse('');
        if (isCaptchaVerified) {
            handleChangePassword();
        } else {
            setSubmitResponse('Please complete the reCAPTCHA verification.');
            setIsSubmitting(false);
        }
    };

    const handleCaptchaChange = (value: string | null) => {
        setRecaptchaResponse(value);
        if (value) {
            setSubmitResponse('');
            setIsCaptchaVerified(true);
        }
    };

    useEffect(() => {
        // clear error messages when user starts to change password
        setNewPasswordValidationFailure('');
    }, [newPassword]);

    const resetCaptcha = () => {
        // Reset the ReCAPTCHA state
        recaptchaRef.current.reset();
        setIsCaptchaVerified(false); // Reset captcha verification status
    };

    return (
        <div>
            <RecaptchaScriptLoader siteKey="6LdSFHwoAAAAAFCQGXiGNRP42m8C9boeTsEvpc9P" />
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" id="emailAddress" name="emailAddress" value={emailAddress} />
                        <input type="hidden" id="dateToken" name="dateToken" value={dateToken} />
                        <input type="hidden" id="emailHash" name="emailHash" value={emailHash} />
                        <div className="mb-3">
                            <label htmlFor="newPassword" className="form-label">New Password</label>
                            <input id="newPassword" name="newPassword" required onChange={e => setNewPassword(e.target.value)} className={newPasswordValidationFailure !== '' ? 'form-control is-invalid' : 'form-control'} type="password" />
                            <div className="invalid-feedback">{newPasswordValidationFailure}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmNewPassword" className="form-label">Confirm New Password</label>
                            <input id="confirmNewPassword" name="confirmNewPassword" required onChange={e => setConfirmNewPassword(e.target.value)} className="form-control" type="password" />
                        </div>
                        <div id="capatcha-container" className="mb-2">
                            <ReCAPTCHA
                                sitekey="6LdSFHwoAAAAAFCQGXiGNRP42m8C9boeTsEvpc9P"
                                onChange={handleCaptchaChange}
                                ref={recaptchaRef}
                            />
                        </div>
                        <Link to="/">
                            <button type="button" className="btn btn-secondary me-3">Return to Login</button>
                        </Link>
                        <button type="submit" disabled={newPassword === '' || confirmNewPassword === '' || isSubmitting || !isCaptchaVerified} className="btn btn-primary">Change Password</button>
                    </form>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1"></div>
                <div className="col-lg-5 col-md-5 col-sm-5">
                    <h5>Password requirements</h5>
                    <ul>
                        <li>At least 8 characters long</li>
                        <li>Less than 31 characters long</li>
                        <li>Contain at least one special character</li>
                        <li>Contain at least one uppercase character</li>
                        <li>Contain at least one lowercase character</li>
                        <li>Contain at least one number</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordForm;