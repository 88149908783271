import React, { useEffect } from 'react';

interface RecaptchaScriptLoaderProps {
    siteKey: string;
}

const RecaptchaScriptLoader: React.FC<RecaptchaScriptLoaderProps> = ({ siteKey }) => {
    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        scriptTag.async = true;
        scriptTag.defer = true;

        scriptTag.onload = () => {
            // The reCAPTCHA script has loaded
        };

        document.body.appendChild(scriptTag);

        return () => {
            // Clean up: remove the script tag when the component is unmounted
            document.body.removeChild(scriptTag);
        };
    }, [siteKey]);

    return null;
};

export default RecaptchaScriptLoader;
